/* eslint-disable react/no-array-index-key */
import terms from 'common/terms'
import { ReactElement, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoIosClose, IoMdAdd, IoMdClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { updateFilters, updateWorkPhases } from 'reducers/library'
import { RootState } from 'Store'
import Phases from './Phases'
import { WorkPhaseFields } from './types'

type Props = {
    filtersValues: any
    onClose: (v: string, index: number) => void
}

export default function WorkPhase({ filtersValues, onClose }: Props): ReactElement {
  const dispatch = useDispatch()
  const { workPhases, filters } = useSelector((state: RootState) => state.library)

  useEffect(() => {
    if (workPhases && workPhases.length === 0) {
      dispatch(updateWorkPhases([{
        index: 1,
        phase: [],
        appliedFilter: false,
      }]))
    }
  }, [workPhases])

  useEffect(() => {
    if (filters['Phases études/travaux'] && filters['Phases études/travaux'].length !== 0
     && workPhases && workPhases.length === 0) {
      const phases: WorkPhaseFields[] = []
      filters['Phases études/travaux'].map((filter: any, index: number) => (
        phases.push({ index: index + 1, phase: filter, appliedFilter: true })
      ))
      dispatch(updateWorkPhases(phases))
    }
  }, [filters])

  const addPhase = () => {
    const newPhaseFields: Array<WorkPhaseFields> = [{
      index: workPhases.length + 1,
      phase: [],
      appliedFilter: false,
    }]
    dispatch(updateWorkPhases(workPhases.concat(newPhaseFields)))
  }

  const deletePhases = () => {
    dispatch(updateWorkPhases(undefined))
    dispatch(updateFilters({ ...filters, 'Phases études/travaux': [] }))
  }

  return (
    <>
      {workPhases && (
      <>
        <div className="d-flex justify-content-between">
          <div className="phase-text px-2">{terms.Portfolio.Library.Filters.workPhase}</div>
          <div className="align-self-center pr-2 action-cursor-pointer">
            <IoMdAdd onClick={addPhase} />
            <IoMdClose onClick={deletePhases} />
          </div>
        </div>
        <Row className="phase-sub-text">
          <Col key="phase">{terms.Portfolio.Library.Filters.workPhase}</Col>
          <Col key="date">{terms.Portfolio.Library.Filters.date}</Col>
        </Row>
      </>
      )}
      {workPhases && workPhases.map((phase: WorkPhaseFields) => (
        <div key={phase.index} className="d-flex">
          <Phases
            index={phase.index}
            workPhasesValue={filtersValues.phaseTravaux}
            dateValues={filtersValues.date}
          />
          <div className="phase-close-btn">
            <IoIosClose
              className="action-cursor-pointer"
              onClick={() => onClose(terms.Portfolio.Library.Filters.workPhase, phase.index)}
            />
          </div>
        </div>
      ))}
    </>
  )
}
